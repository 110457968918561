/*
 * Copyright 2010-2020 JetBrains s.r.o. and Kotlin Programming Language contributors.
 * Use of this source code is governed by the Apache 2.0 license that can be found in the license/LICENSE.txt file.
 */

@file:kotlin.jvm.JvmMultifileClass
@file:kotlin.jvm.JvmName("StringsKt")

package kotlin.text

//
// NOTE: THIS FILE IS AUTO-GENERATED by the GenerateStandardLib.kt
// See: https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib
//

import kotlin.random.*

/**
 * Returns a character at the given [index] or throws an [IndexOutOfBoundsException] if the [index] is out of bounds of this char sequence.
 * 
 * @sample samples.collections.Collections.Elements.elementAt
 */
public expect fun CharSequence.elementAt(index: Int): Char

/**
 * Returns a character at the given [index] or the result of calling the [defaultValue] function if the [index] is out of bounds of this char sequence.
 * 
 * @sample samples.collections.Collections.Elements.elementAtOrElse
 */
@kotlin.internal.InlineOnly
public inline fun CharSequence.elementAtOrElse(index: Int, defaultValue: (Int) -> Char): Char {
    return if (index >= 0 && index <= lastIndex) get(index) else defaultValue(index)
}

/**
 * Returns a character at the given [index] or `null` if the [index] is out of bounds of this char sequence.
 * 
 * @sample samples.collections.Collections.Elements.elementAtOrNull
 */
@kotlin.internal.InlineOnly
public inline fun CharSequence.elementAtOrNull(index: Int): Char? {
    return this.getOrNull(index)
}

/**
 * Returns the first character matching the given [predicate], or `null` if no such character was found.
 * 
 * @sample samples.collections.Collections.Elements.find
 */
@kotlin.internal.InlineOnly
public inline fun CharSequence.find(predicate: (Char) -> Boolean): Char? {
    return firstOrNull(predicate)
}

/**
 * Returns the last character matching the given [predicate], or `null` if no such character was found.
 * 
 * @sample samples.collections.Collections.Elements.find
 */
@kotlin.internal.InlineOnly
public inline fun CharSequence.findLast(predicate: (Char) -> Boolean): Char? {
    return lastOrNull(predicate)
}

/**
 * Returns first character.
 * @throws [NoSuchElementException] if the char sequence is empty.
 */
public fun CharSequence.first(): Char {
    if (isEmpty())
        throw NoSuchElementException("Char sequence is empty.")
    return this[0]
}

/**
 * Returns the first character matching the given [predicate].
 * @throws [NoSuchElementException] if no such character is found.
 */
public inline fun CharSequence.first(predicate: (Char) -> Boolean): Char {
    for (element in this) if (predicate(element)) return element
    throw NoSuchElementException("Char sequence contains no character matching the predicate.")
}

/**
 * Returns the first character, or `null` if the char sequence is empty.
 */
public fun CharSequence.firstOrNull(): Char? {
    return if (isEmpty()) null else this[0]
}

/**
 * Returns the first character matching the given [predicate], or `null` if character was not found.
 */
public inline fun CharSequence.firstOrNull(predicate: (Char) -> Boolean): Char? {
    for (element in this) if (predicate(element)) return element
    return null
}

/**
 * Returns a character at the given [index] or the result of calling the [defaultValue] function if the [index] is out of bounds of this char sequence.
 */
@kotlin.internal.InlineOnly
public inline fun CharSequence.getOrElse(index: Int, defaultValue: (Int) -> Char): Char {
    return if (index >= 0 && index <= lastIndex) get(index) else defaultValue(index)
}

/**
 * Returns a character at the given [index] or `null` if the [index] is out of bounds of this char sequence.
 * 
 * @sample samples.collections.Collections.Elements.getOrNull
 */
public fun CharSequence.getOrNull(index: Int): Char? {
    return if (index >= 0 && index <= lastIndex) get(index) else null
}

/**
 * Returns index of the first character matching the given [predicate], or -1 if the char sequence does not contain such character.
 */
public inline fun CharSequence.indexOfFirst(predicate: (Char) -> Boolean): Int {
    for (index in indices) {
        if (predicate(this[index])) {
            return index
        }
    }
    return -1
}

/**
 * Returns index of the last character matching the given [predicate], or -1 if the char sequence does not contain such character.
 */
public inline fun CharSequence.indexOfLast(predicate: (Char) -> Boolean): Int {
    for (index in indices.reversed()) {
        if (predicate(this[index])) {
            return index
        }
    }
    return -1
}

/**
 * Returns the last character.
 * @throws [NoSuchElementException] if the char sequence is empty.
 */
public fun CharSequence.last(): Char {
    if (isEmpty())
        throw NoSuchElementException("Char sequence is empty.")
    return this[lastIndex]
}

/**
 * Returns the last character matching the given [predicate].
 * @throws [NoSuchElementException] if no such character is found.
 */
public inline fun CharSequence.last(predicate: (Char) -> Boolean): Char {
    for (index in this.indices.reversed()) {
        val element = this[index]
        if (predicate(element)) return element
    }
    throw NoSuchElementException("Char sequence contains no character matching the predicate.")
}

/**
 * Returns the last character, or `null` if the char sequence is empty.
 */
public fun CharSequence.lastOrNull(): Char? {
    return if (isEmpty()) null else this[length - 1]
}

/**
 * Returns the last character matching the given [predicate], or `null` if no such character was found.
 */
public inline fun CharSequence.lastOrNull(predicate: (Char) -> Boolean): Char? {
    for (index in this.indices.reversed()) {
        val element = this[index]
        if (predicate(element)) return element
    }
    return null
}

/**
 * Returns a random character from this char sequence.
 * 
 * @throws NoSuchElementException if this char sequence is empty.
 */
@SinceKotlin("1.3")
@kotlin.internal.InlineOnly
public inline fun CharSequence.random(): Char {
    return random(Random)
}

/**
 * Returns a random character from this char sequence using the specified source of randomness.
 * 
 * @throws NoSuchElementException if this char sequence is empty.
 */
@SinceKotlin("1.3")
public fun CharSequence.random(random: Random): Char {
    if (isEmpty())
        throw NoSuchElementException("Char sequence is empty.")
    return get(random.nextInt(length))
}

/**
 * Returns a random character from this char sequence, or `null` if this char sequence is empty.
 */
@SinceKotlin("1.4")
@WasExperimental(ExperimentalStdlibApi::class)
@kotlin.internal.InlineOnly
public inline fun CharSequence.randomOrNull(): Char? {
    return randomOrNull(Random)
}

/**
 * Returns a random character from this char sequence using the specified source of randomness, or `null` if this char sequence is empty.
 */
@SinceKotlin("1.4")
@WasExperimental(ExperimentalStdlibApi::class)
public fun CharSequence.randomOrNull(random: Random): Char? {
    if (isEmpty())
        return null
    return get(random.nextInt(length))
}

/**
 * Returns the single character, or throws an exception if the char sequence is empty or has more than one character.
 */
public fun CharSequence.single(): Char {
    return when (length) {
        0 -> throw NoSuchElementException("Char sequence is empty.")
        1 -> this[0]
        else -> throw IllegalArgumentException("Char sequence has more than one element.")
    }
}

/**
 * Returns the single character matching the given [predicate], or throws exception if there is no or more than one matching character.
 */
public inline fun CharSequence.single(predicate: (Char) -> Boolean): Char {
    var single: Char? = null
    var found = false
    for (element in this) {
        if (predicate(element)) {
            if (found) throw IllegalArgumentException("Char sequence contains more than one matching element.")
            single = element
            found = true
        }
    }
    if (!found) throw NoSuchElementException("Char sequence contains no character matching the predicate.")
    @Suppress("UNCHECKED_CAST")
    return single as Char
}

/**
 * Returns single character, or `null` if the char sequence is empty or has more than one character.
 */
public fun CharSequence.singleOrNull(): Char? {
    return if (length == 1) this[0] else null
}

/**
 * Returns the single character matching the given [predicate], or `null` if character was not found or more than one character was found.
 */
public inline fun CharSequence.singleOrNull(predicate: (Char) -> Boolean): Char? {
    var single: Char? = null
    var found = false
    for (element in this) {
        if (predicate(element)) {
            if (found) return null
            single = element
            found = true
        }
    }
    if (!found) return null
    return single
}

/**
 * Returns a subsequence of this char sequence with the first [n] characters removed.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.drop
 */
public fun CharSequence.drop(n: Int): CharSequence {
    require(n >= 0) { "Requested character count $n is less than zero." }
    return subSequence(n.coerceAtMost(length), length)
}

/**
 * Returns a string with the first [n] characters removed.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.drop
 */
public fun String.drop(n: Int): String {
    require(n >= 0) { "Requested character count $n is less than zero." }
    return substring(n.coerceAtMost(length))
}

/**
 * Returns a subsequence of this char sequence with the last [n] characters removed.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.drop
 */
public fun CharSequence.dropLast(n: Int): CharSequence {
    require(n >= 0) { "Requested character count $n is less than zero." }
    return take((length - n).coerceAtLeast(0))
}

/**
 * Returns a string with the last [n] characters removed.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.drop
 */
public fun String.dropLast(n: Int): String {
    require(n >= 0) { "Requested character count $n is less than zero." }
    return take((length - n).coerceAtLeast(0))
}

/**
 * Returns a subsequence of this char sequence containing all characters except last characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.drop
 */
public inline fun CharSequence.dropLastWhile(predicate: (Char) -> Boolean): CharSequence {
    for (index in lastIndex downTo 0)
        if (!predicate(this[index]))
            return subSequence(0, index + 1)
    return ""
}

/**
 * Returns a string containing all characters except last characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.drop
 */
public inline fun String.dropLastWhile(predicate: (Char) -> Boolean): String {
    for (index in lastIndex downTo 0)
        if (!predicate(this[index]))
            return substring(0, index + 1)
    return ""
}

/**
 * Returns a subsequence of this char sequence containing all characters except first characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.drop
 */
public inline fun CharSequence.dropWhile(predicate: (Char) -> Boolean): CharSequence {
    for (index in this.indices)
        if (!predicate(this[index]))
            return subSequence(index, length)
    return ""
}

/**
 * Returns a string containing all characters except first characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.drop
 */
public inline fun String.dropWhile(predicate: (Char) -> Boolean): String {
    for (index in this.indices)
        if (!predicate(this[index]))
            return substring(index)
    return ""
}

/**
 * Returns a char sequence containing only those characters from the original char sequence that match the given [predicate].
 * 
 * @sample samples.text.Strings.filter
 */
public inline fun CharSequence.filter(predicate: (Char) -> Boolean): CharSequence {
    return filterTo(StringBuilder(), predicate)
}

/**
 * Returns a string containing only those characters from the original string that match the given [predicate].
 * 
 * @sample samples.text.Strings.filter
 */
public inline fun String.filter(predicate: (Char) -> Boolean): String {
    return filterTo(StringBuilder(), predicate).toString()
}

/**
 * Returns a char sequence containing only those characters from the original char sequence that match the given [predicate].
 * @param [predicate] function that takes the index of a character and the character itself
 * and returns the result of predicate evaluation on the character.
 * 
 * @sample samples.collections.Collections.Filtering.filterIndexed
 */
public inline fun CharSequence.filterIndexed(predicate: (index: Int, Char) -> Boolean): CharSequence {
    return filterIndexedTo(StringBuilder(), predicate)
}

/**
 * Returns a string containing only those characters from the original string that match the given [predicate].
 * @param [predicate] function that takes the index of a character and the character itself
 * and returns the result of predicate evaluation on the character.
 * 
 * @sample samples.collections.Collections.Filtering.filterIndexed
 */
public inline fun String.filterIndexed(predicate: (index: Int, Char) -> Boolean): String {
    return filterIndexedTo(StringBuilder(), predicate).toString()
}

/**
 * Appends all characters matching the given [predicate] to the given [destination].
 * @param [predicate] function that takes the index of a character and the character itself
 * and returns the result of predicate evaluation on the character.
 * 
 * @sample samples.collections.Collections.Filtering.filterIndexedTo
 */
public inline fun <C : Appendable> CharSequence.filterIndexedTo(destination: C, predicate: (index: Int, Char) -> Boolean): C {
    forEachIndexed { index, element ->
        if (predicate(index, element)) destination.append(element)
    }
    return destination
}

/**
 * Returns a char sequence containing only those characters from the original char sequence that do not match the given [predicate].
 * 
 * @sample samples.text.Strings.filterNot
 */
public inline fun CharSequence.filterNot(predicate: (Char) -> Boolean): CharSequence {
    return filterNotTo(StringBuilder(), predicate)
}

/**
 * Returns a string containing only those characters from the original string that do not match the given [predicate].
 * 
 * @sample samples.text.Strings.filterNot
 */
public inline fun String.filterNot(predicate: (Char) -> Boolean): String {
    return filterNotTo(StringBuilder(), predicate).toString()
}

/**
 * Appends all characters not matching the given [predicate] to the given [destination].
 * 
 * @sample samples.collections.Collections.Filtering.filterTo
 */
public inline fun <C : Appendable> CharSequence.filterNotTo(destination: C, predicate: (Char) -> Boolean): C {
    for (element in this) if (!predicate(element)) destination.append(element)
    return destination
}

/**
 * Appends all characters matching the given [predicate] to the given [destination].
 * 
 * @sample samples.collections.Collections.Filtering.filterTo
 */
public inline fun <C : Appendable> CharSequence.filterTo(destination: C, predicate: (Char) -> Boolean): C {
    for (index in 0 until length) {
        val element = get(index)
        if (predicate(element)) destination.append(element)
    }
    return destination
}

/**
 * Returns a char sequence containing characters of the original char sequence at the specified range of [indices].
 */
public fun CharSequence.slice(indices: IntRange): CharSequence {
    if (indices.isEmpty()) return ""
    return subSequence(indices)
}

/**
 * Returns a string containing characters of the original string at the specified range of [indices].
 */
public fun String.slice(indices: IntRange): String {
    if (indices.isEmpty()) return ""
    return substring(indices)
}

/**
 * Returns a char sequence containing characters of the original char sequence at specified [indices].
 */
public fun CharSequence.slice(indices: Iterable<Int>): CharSequence {
    val size = indices.collectionSizeOrDefault(10)
    if (size == 0) return ""
    val result = StringBuilder(size)
    for (i in indices) {
        result.append(get(i))
    }
    return result
}

/**
 * Returns a string containing characters of the original string at specified [indices].
 */
@kotlin.internal.InlineOnly
public inline fun String.slice(indices: Iterable<Int>): String {
    return (this as CharSequence).slice(indices).toString()
}

/**
 * Returns a subsequence of this char sequence containing the first [n] characters from this char sequence, or the entire char sequence if this char sequence is shorter.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.take
 */
public fun CharSequence.take(n: Int): CharSequence {
    require(n >= 0) { "Requested character count $n is less than zero." }
    return subSequence(0, n.coerceAtMost(length))
}

/**
 * Returns a string containing the first [n] characters from this string, or the entire string if this string is shorter.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.take
 */
public fun String.take(n: Int): String {
    require(n >= 0) { "Requested character count $n is less than zero." }
    return substring(0, n.coerceAtMost(length))
}

/**
 * Returns a subsequence of this char sequence containing the last [n] characters from this char sequence, or the entire char sequence if this char sequence is shorter.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.take
 */
public fun CharSequence.takeLast(n: Int): CharSequence {
    require(n >= 0) { "Requested character count $n is less than zero." }
    val length = length
    return subSequence(length - n.coerceAtMost(length), length)
}

/**
 * Returns a string containing the last [n] characters from this string, or the entire string if this string is shorter.
 * 
 * @throws IllegalArgumentException if [n] is negative.
 * 
 * @sample samples.text.Strings.take
 */
public fun String.takeLast(n: Int): String {
    require(n >= 0) { "Requested character count $n is less than zero." }
    val length = length
    return substring(length - n.coerceAtMost(length))
}

/**
 * Returns a subsequence of this char sequence containing last characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.take
 */
public inline fun CharSequence.takeLastWhile(predicate: (Char) -> Boolean): CharSequence {
    for (index in lastIndex downTo 0) {
        if (!predicate(this[index])) {
            return subSequence(index + 1, length)
        }
    }
    return subSequence(0, length)
}

/**
 * Returns a string containing last characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.take
 */
public inline fun String.takeLastWhile(predicate: (Char) -> Boolean): String {
    for (index in lastIndex downTo 0) {
        if (!predicate(this[index])) {
            return substring(index + 1)
        }
    }
    return this
}

/**
 * Returns a subsequence of this char sequence containing the first characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.take
 */
public inline fun CharSequence.takeWhile(predicate: (Char) -> Boolean): CharSequence {
    for (index in 0 until length)
        if (!predicate(get(index))) {
            return subSequence(0, index)
        }
    return subSequence(0, length)
}

/**
 * Returns a string containing the first characters that satisfy the given [predicate].
 * 
 * @sample samples.text.Strings.take
 */
public inline fun String.takeWhile(predicate: (Char) -> Boolean): String {
    for (index in 0 until length)
        if (!predicate(get(index))) {
            return substring(0, index)
        }
    return this
}

/**
 * Returns a char sequence with characters in reversed order.
 */
public fun CharSequence.reversed(): CharSequence {
    return StringBuilder(this).reverse()
}

/**
 * Returns a string with characters in reversed order.
 */
@kotlin.internal.InlineOnly
public inline fun String.reversed(): String {
    return (this as CharSequence).reversed().toString()
}

/**
 * Returns a [Map] containing key-value pairs provided by [transform] function
 * applied to characters of the given char sequence.
 * 
 * If any of two pairs would have the same key the last one gets added to the map.
 * 
 * The returned map preserves the entry iteration order of the original char sequence.
 * 
 * @sample samples.text.Strings.associate
 */
public inline fun <K, V> CharSequence.associate(transform: (Char) -> Pair<K, V>): Map<K, V> {
    val capacity = mapCapacity(length).coerceAtLeast(16)
    return associateTo(LinkedHashMap<K, V>(capacity), transform)
}

/**
 * Returns a [Map] containing the characters from the given char sequence indexed by the key
 * returned from [keySelector] function applied to each character.
 * 
 * If any two characters would have the same key returned by [keySelector] the last one gets added to the map.
 * 
 * The returned map preserves the entry iteration order of the original char sequence.
 * 
 * @sample samples.text.Strings.associateBy
 */
public inline fun <K> CharSequence.associateBy(keySelector: (Char) -> K): Map<K, Char> {
    val capacity = mapCapacity(length).coerceAtLeast(16)
    return associateByTo(LinkedHashMap<K, Char>(capacity), keySelector)
}

/**
 * Returns a [Map] containing the values provided by [valueTransform] and indexed by [keySelector] functions applied to characters of the given char sequence.
 * 
 * If any two characters would have the same key returned by [keySelector] the last one gets added to the map.
 * 
 * The returned map preserves the entry iteration order of the original char sequence.
 * 
 * @sample samples.text.Strings.associateByWithValueTransform
 */
public inline fun <K, V> CharSequence.associateBy(keySelector: (Char) -> K, valueTransform: (Char) -> V): Map<K, V> {
    val capacity = mapCapacity(length).coerceAtLeast(16)
    return associateByTo(LinkedHashMap<K, V>(capacity), keySelector, valueTransform)
}

/**
 * Populates and returns the [destination] mutable map with key-value pairs,
 * where key is provided by the [keySelector] function applied to each character of the given char sequence
 * and value is the character itself.
 * 
 * If any two characters would have the same key returned by [keySelector] the last one gets added to the map.
 * 
 * @sample samples.text.Strings.associateByTo
 */
public inline fun <K, M : MutableMap<in K, in Char>> CharSequence.associateByTo(destination: M, keySelector: (Char) -> K): M {
    for (element in this) {
        destination.put(keySelector(element), element)
    }
    return destination
}

/**
 * Populates and returns the [destination] mutable map with key-value pairs,
 * where key is provided by the [keySelector] function and
 * and value is provided by the [valueTransform] function applied to characters of the given char sequence.
 * 
 * If any two characters would have the same key returned by [keySelector] the last one gets added to the map.
 * 
 * @sample samples.text.Strings.associateByToWithValueTransform
 */
public inline fun <K, V, M : MutableMap<in K, in V>> CharSequence.associateByTo(destination: M, keySelector: (Char) -> K, valueTransform: (Char) -> V): M {
    for (element in this) {
        destination.put(keySelector(element), valueTransform(element))
    }
    return destination
}

/**
 * Populates and returns the [destination] mutable map with key-value pairs
 * provided by [transform] function applied to each character of the given char sequence.
 * 
 * If any of two pairs would have the same key the last one gets added to the map.
 * 
 * @sample samples.text.Strings.associateTo
 */
public inline fun <K, V, M : MutableMap<in K, in V>> CharSequence.associateTo(destination: M, transform: (Char) -> Pair<K, V>): M {
    for (element in this) {
        destination += transform(element)
    }
    return destination
}

/**
 * Returns a [Map] where keys are characters from the given char sequence and values are
 * produced by the [valueSelector] function applied to each character.
 * 
 * If any two characters are equal, the last one gets added to the map.
 * 
 * The returned map preserves the entry iteration order of the original char sequence.
 * 
 * @sample samples.text.Strings.associateWith
 */
@SinceKotlin("1.3")
public inline fun <V> CharSequence.associateWith(valueSelector: (Char) -> V): Map<Char, V> {
    val result = LinkedHashMap<Char, V>(mapCapacity(length.coerceAtMost(128)).coerceAtLeast(16))
    return associateWithTo(result, valueSelector)
}

/**
 * Populates and returns the [destination] mutable map with key-value pairs for each character of the given char sequence,
 * where key is the character itself and value is provided by the [valueSelector] function applied to that key.
 * 
 * If any two characters are equal, the last one overwrites the former value in the map.
 * 
 * @sample samples.text.Strings.associateWithTo
 */
@SinceKotlin("1.3")
public inline fun <V, M : MutableMap<in Char, in V>> CharSequence.associateWithTo(destination: M, valueSelector: (Char) -> V): M {
    for (element in this) {
        destination.put(element, valueSelector(element))
    }
    return destination
}

/**
 * Appends all characters to the given [destination] collection.
 */
public fun <C : MutableCollection<in Char>> CharSequence.toCollection(destination: C): C {
    for (item in this) {
        destination.add(item)
    }
    return destination
}

/**
 * Returns a new [HashSet] of all characters.
 */
public fun CharSequence.toHashSet(): HashSet<Char> {
    return toCollection(HashSet<Char>(mapCapacity(length.coerceAtMost(128))))
}

/**
 * Returns a [List] containing all characters.
 */
public fun CharSequence.toList(): List<Char> {
    return when (length) {
        0 -> emptyList()
        1 -> listOf(this[0])
        else -> this.toMutableList()
    }
}

/**
 * Returns a new [MutableList] filled with all characters of this char sequence.
 */
public fun CharSequence.toMutableList(): MutableList<Char> {
    return toCollection(ArrayList<Char>(length))
}

/**
 * Returns a [Set] of all characters.
 * 
 * The returned set preserves the element iteration order of the original char sequence.
 */
public fun CharSequence.toSet(): Set<Char> {
    return when (length) {
        0 -> emptySet()
        1 -> setOf(this[0])
        else -> toCollection(LinkedHashSet<Char>(mapCapacity(length.coerceAtMost(128))))
    }
}

/**
 * Returns a single list of all elements yielded from results of [transform] function being invoked on each character of original char sequence.
 * 
 * @sample samples.collections.Collections.Transformations.flatMap
 */
public inline fun <R> CharSequence.flatMap(transform: (Char) -> Iterable<R>): List<R> {
    return flatMapTo(ArrayList<R>(), transform)
}

/**
 * Returns a single list of all elements yielded from results of [transform] function being invoked on each character
 * and its index in the original char sequence.
 * 
 * @sample samples.collections.Collections.Transformations.flatMapIndexed
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("flatMapIndexedIterable")
@kotlin.internal.InlineOnly
public inline fun <R> CharSequence.flatMapIndexed(transform: (index: Int, Char) -> Iterable<R>): List<R> {
    return flatMapIndexedTo(ArrayList<R>(), transform)
}

/**
 * Appends all elements yielded from results of [transform] function being invoked on each character
 * and its index in the original char sequence, to the given [destination].
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("flatMapIndexedIterableTo")
@kotlin.internal.InlineOnly
public inline fun <R, C : MutableCollection<in R>> CharSequence.flatMapIndexedTo(destination: C, transform: (index: Int, Char) -> Iterable<R>): C {
    var index = 0
    for (element in this) {
        val list = transform(index++, element)
        destination.addAll(list)
    }
    return destination
}

/**
 * Appends all elements yielded from results of [transform] function being invoked on each character of original char sequence, to the given [destination].
 */
public inline fun <R, C : MutableCollection<in R>> CharSequence.flatMapTo(destination: C, transform: (Char) -> Iterable<R>): C {
    for (element in this) {
        val list = transform(element)
        destination.addAll(list)
    }
    return destination
}

/**
 * Groups characters of the original char sequence by the key returned by the given [keySelector] function
 * applied to each character and returns a map where each group key is associated with a list of corresponding characters.
 * 
 * The returned map preserves the entry iteration order of the keys produced from the original char sequence.
 * 
 * @sample samples.collections.Collections.Transformations.groupBy
 */
public inline fun <K> CharSequence.groupBy(keySelector: (Char) -> K): Map<K, List<Char>> {
    return groupByTo(LinkedHashMap<K, MutableList<Char>>(), keySelector)
}

/**
 * Groups values returned by the [valueTransform] function applied to each character of the original char sequence
 * by the key returned by the given [keySelector] function applied to the character
 * and returns a map where each group key is associated with a list of corresponding values.
 * 
 * The returned map preserves the entry iteration order of the keys produced from the original char sequence.
 * 
 * @sample samples.collections.Collections.Transformations.groupByKeysAndValues
 */
public inline fun <K, V> CharSequence.groupBy(keySelector: (Char) -> K, valueTransform: (Char) -> V): Map<K, List<V>> {
    return groupByTo(LinkedHashMap<K, MutableList<V>>(), keySelector, valueTransform)
}

/**
 * Groups characters of the original char sequence by the key returned by the given [keySelector] function
 * applied to each character and puts to the [destination] map each group key associated with a list of corresponding characters.
 * 
 * @return The [destination] map.
 * 
 * @sample samples.collections.Collections.Transformations.groupBy
 */
public inline fun <K, M : MutableMap<in K, MutableList<Char>>> CharSequence.groupByTo(destination: M, keySelector: (Char) -> K): M {
    for (element in this) {
        val key = keySelector(element)
        val list = destination.getOrPut(key) { ArrayList<Char>() }
        list.add(element)
    }
    return destination
}

/**
 * Groups values returned by the [valueTransform] function applied to each character of the original char sequence
 * by the key returned by the given [keySelector] function applied to the character
 * and puts to the [destination] map each group key associated with a list of corresponding values.
 * 
 * @return The [destination] map.
 * 
 * @sample samples.collections.Collections.Transformations.groupByKeysAndValues
 */
public inline fun <K, V, M : MutableMap<in K, MutableList<V>>> CharSequence.groupByTo(destination: M, keySelector: (Char) -> K, valueTransform: (Char) -> V): M {
    for (element in this) {
        val key = keySelector(element)
        val list = destination.getOrPut(key) { ArrayList<V>() }
        list.add(valueTransform(element))
    }
    return destination
}

/**
 * Creates a [Grouping] source from a char sequence to be used later with one of group-and-fold operations
 * using the specified [keySelector] function to extract a key from each character.
 * 
 * @sample samples.collections.Grouping.groupingByEachCount
 */
@SinceKotlin("1.1")
public inline fun <K> CharSequence.groupingBy(crossinline keySelector: (Char) -> K): Grouping<Char, K> {
    return object : Grouping<Char, K> {
        override fun sourceIterator(): Iterator<Char> = this@groupingBy.iterator()
        override fun keyOf(element: Char): K = keySelector(element)
    }
}

/**
 * Returns a list containing the results of applying the given [transform] function
 * to each character in the original char sequence.
 * 
 * @sample samples.text.Strings.map
 */
public inline fun <R> CharSequence.map(transform: (Char) -> R): List<R> {
    return mapTo(ArrayList<R>(length), transform)
}

/**
 * Returns a list containing the results of applying the given [transform] function
 * to each character and its index in the original char sequence.
 * @param [transform] function that takes the index of a character and the character itself
 * and returns the result of the transform applied to the character.
 */
public inline fun <R> CharSequence.mapIndexed(transform: (index: Int, Char) -> R): List<R> {
    return mapIndexedTo(ArrayList<R>(length), transform)
}

/**
 * Returns a list containing only the non-null results of applying the given [transform] function
 * to each character and its index in the original char sequence.
 * @param [transform] function that takes the index of a character and the character itself
 * and returns the result of the transform applied to the character.
 */
public inline fun <R : Any> CharSequence.mapIndexedNotNull(transform: (index: Int, Char) -> R?): List<R> {
    return mapIndexedNotNullTo(ArrayList<R>(), transform)
}

/**
 * Applies the given [transform] function to each character and its index in the original char sequence
 * and appends only the non-null results to the given [destination].
 * @param [transform] function that takes the index of a character and the character itself
 * and returns the result of the transform applied to the character.
 */
public inline fun <R : Any, C : MutableCollection<in R>> CharSequence.mapIndexedNotNullTo(destination: C, transform: (index: Int, Char) -> R?): C {
    forEachIndexed { index, element -> transform(index, element)?.let { destination.add(it) } }
    return destination
}

/**
 * Applies the given [transform] function to each character and its index in the original char sequence
 * and appends the results to the given [destination].
 * @param [transform] function that takes the index of a character and the character itself
 * and returns the result of the transform applied to the character.
 */
public inline fun <R, C : MutableCollection<in R>> CharSequence.mapIndexedTo(destination: C, transform: (index: Int, Char) -> R): C {
    var index = 0
    for (item in this)
        destination.add(transform(index++, item))
    return destination
}

/**
 * Returns a list containing only the non-null results of applying the given [transform] function
 * to each character in the original char sequence.
 * 
 * @sample samples.collections.Collections.Transformations.mapNotNull
 */
public inline fun <R : Any> CharSequence.mapNotNull(transform: (Char) -> R?): List<R> {
    return mapNotNullTo(ArrayList<R>(), transform)
}

/**
 * Applies the given [transform] function to each character in the original char sequence
 * and appends only the non-null results to the given [destination].
 */
public inline fun <R : Any, C : MutableCollection<in R>> CharSequence.mapNotNullTo(destination: C, transform: (Char) -> R?): C {
    forEach { element -> transform(element)?.let { destination.add(it) } }
    return destination
}

/**
 * Applies the given [transform] function to each character of the original char sequence
 * and appends the results to the given [destination].
 */
public inline fun <R, C : MutableCollection<in R>> CharSequence.mapTo(destination: C, transform: (Char) -> R): C {
    for (item in this)
        destination.add(transform(item))
    return destination
}

/**
 * Returns a lazy [Iterable] that wraps each character of the original char sequence
 * into an [IndexedValue] containing the index of that character and the character itself.
 */
public fun CharSequence.withIndex(): Iterable<IndexedValue<Char>> {
    return IndexingIterable { iterator() }
}

/**
 * Returns `true` if all characters match the given [predicate].
 * 
 * @sample samples.collections.Collections.Aggregates.all
 */
public inline fun CharSequence.all(predicate: (Char) -> Boolean): Boolean {
    for (element in this) if (!predicate(element)) return false
    return true
}

/**
 * Returns `true` if char sequence has at least one character.
 * 
 * @sample samples.collections.Collections.Aggregates.any
 */
public fun CharSequence.any(): Boolean {
    return !isEmpty()
}

/**
 * Returns `true` if at least one character matches the given [predicate].
 * 
 * @sample samples.collections.Collections.Aggregates.anyWithPredicate
 */
public inline fun CharSequence.any(predicate: (Char) -> Boolean): Boolean {
    for (element in this) if (predicate(element)) return true
    return false
}

/**
 * Returns the length of this char sequence.
 */
@kotlin.internal.InlineOnly
public inline fun CharSequence.count(): Int {
    return length
}

/**
 * Returns the number of characters matching the given [predicate].
 */
public inline fun CharSequence.count(predicate: (Char) -> Boolean): Int {
    var count = 0
    for (element in this) if (predicate(element)) ++count
    return count
}

/**
 * Accumulates value starting with [initial] value and applying [operation] from left to right
 * to current accumulator value and each character.
 * 
 * Returns the specified [initial] value if the char sequence is empty.
 * 
 * @param [operation] function that takes current accumulator value and a character, and calculates the next accumulator value.
 */
public inline fun <R> CharSequence.fold(initial: R, operation: (acc: R, Char) -> R): R {
    var accumulator = initial
    for (element in this) accumulator = operation(accumulator, element)
    return accumulator
}

/**
 * Accumulates value starting with [initial] value and applying [operation] from left to right
 * to current accumulator value and each character with its index in the original char sequence.
 * 
 * Returns the specified [initial] value if the char sequence is empty.
 * 
 * @param [operation] function that takes the index of a character, current accumulator value
 * and the character itself, and calculates the next accumulator value.
 */
public inline fun <R> CharSequence.foldIndexed(initial: R, operation: (index: Int, acc: R, Char) -> R): R {
    var index = 0
    var accumulator = initial
    for (element in this) accumulator = operation(index++, accumulator, element)
    return accumulator
}

/**
 * Accumulates value starting with [initial] value and applying [operation] from right to left
 * to each character and current accumulator value.
 * 
 * Returns the specified [initial] value if the char sequence is empty.
 * 
 * @param [operation] function that takes a character and current accumulator value, and calculates the next accumulator value.
 */
public inline fun <R> CharSequence.foldRight(initial: R, operation: (Char, acc: R) -> R): R {
    var index = lastIndex
    var accumulator = initial
    while (index >= 0) {
        accumulator = operation(get(index--), accumulator)
    }
    return accumulator
}

/**
 * Accumulates value starting with [initial] value and applying [operation] from right to left
 * to each character with its index in the original char sequence and current accumulator value.
 * 
 * Returns the specified [initial] value if the char sequence is empty.
 * 
 * @param [operation] function that takes the index of a character, the character itself
 * and current accumulator value, and calculates the next accumulator value.
 */
public inline fun <R> CharSequence.foldRightIndexed(initial: R, operation: (index: Int, Char, acc: R) -> R): R {
    var index = lastIndex
    var accumulator = initial
    while (index >= 0) {
        accumulator = operation(index, get(index), accumulator)
        --index
    }
    return accumulator
}

/**
 * Performs the given [action] on each character.
 */
public inline fun CharSequence.forEach(action: (Char) -> Unit): Unit {
    for (element in this) action(element)
}

/**
 * Performs the given [action] on each character, providing sequential index with the character.
 * @param [action] function that takes the index of a character and the character itself
 * and performs the action on the character.
 */
public inline fun CharSequence.forEachIndexed(action: (index: Int, Char) -> Unit): Unit {
    var index = 0
    for (item in this) action(index++, item)
}

@Deprecated("Use maxOrNull instead.", ReplaceWith("maxOrNull()"))
@DeprecatedSinceKotlin(warningSince = "1.4")
public fun CharSequence.max(): Char? {
    return maxOrNull()
}

@Deprecated("Use maxByOrNull instead.", ReplaceWith("maxByOrNull(selector)"))
@DeprecatedSinceKotlin(warningSince = "1.4")
public inline fun <R : Comparable<R>> CharSequence.maxBy(selector: (Char) -> R): Char? {
    return maxByOrNull(selector)
}

/**
 * Returns the first character yielding the largest value of the given function or `null` if there are no characters.
 * 
 * @sample samples.collections.Collections.Aggregates.maxByOrNull
 */
@SinceKotlin("1.4")
public inline fun <R : Comparable<R>> CharSequence.maxByOrNull(selector: (Char) -> R): Char? {
    if (isEmpty()) return null
    var maxElem = this[0]
    val lastIndex = this.lastIndex
    if (lastIndex == 0) return maxElem
    var maxValue = selector(maxElem)
    for (i in 1..lastIndex) {
        val e = this[i]
        val v = selector(e)
        if (maxValue < v) {
            maxElem = e
            maxValue = v
        }
    }
    return maxElem
}

/**
 * Returns the largest value among all values produced by [selector] function
 * applied to each character in the char sequence.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.maxOf(selector: (Char) -> Double): Double {
    if (isEmpty()) throw NoSuchElementException()
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        maxValue = maxOf(maxValue, v)
    }
    return maxValue
}

/**
 * Returns the largest value among all values produced by [selector] function
 * applied to each character in the char sequence.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.maxOf(selector: (Char) -> Float): Float {
    if (isEmpty()) throw NoSuchElementException()
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        maxValue = maxOf(maxValue, v)
    }
    return maxValue
}

/**
 * Returns the largest value among all values produced by [selector] function
 * applied to each character in the char sequence.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R : Comparable<R>> CharSequence.maxOf(selector: (Char) -> R): R {
    if (isEmpty()) throw NoSuchElementException()
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (maxValue < v) {
            maxValue = v
        }
    }
    return maxValue
}

/**
 * Returns the largest value among all values produced by [selector] function
 * applied to each character in the char sequence or `null` if there are no characters.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.maxOfOrNull(selector: (Char) -> Double): Double? {
    if (isEmpty()) return null
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        maxValue = maxOf(maxValue, v)
    }
    return maxValue
}

/**
 * Returns the largest value among all values produced by [selector] function
 * applied to each character in the char sequence or `null` if there are no characters.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.maxOfOrNull(selector: (Char) -> Float): Float? {
    if (isEmpty()) return null
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        maxValue = maxOf(maxValue, v)
    }
    return maxValue
}

/**
 * Returns the largest value among all values produced by [selector] function
 * applied to each character in the char sequence or `null` if there are no characters.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R : Comparable<R>> CharSequence.maxOfOrNull(selector: (Char) -> R): R? {
    if (isEmpty()) return null
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (maxValue < v) {
            maxValue = v
        }
    }
    return maxValue
}

/**
 * Returns the largest value according to the provided [comparator]
 * among all values produced by [selector] function applied to each character in the char sequence.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R> CharSequence.maxOfWith(comparator: Comparator<in R>, selector: (Char) -> R): R {
    if (isEmpty()) throw NoSuchElementException()
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (comparator.compare(maxValue, v) < 0) {
            maxValue = v
        }
    }
    return maxValue
}

/**
 * Returns the largest value according to the provided [comparator]
 * among all values produced by [selector] function applied to each character in the char sequence or `null` if there are no characters.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R> CharSequence.maxOfWithOrNull(comparator: Comparator<in R>, selector: (Char) -> R): R? {
    if (isEmpty()) return null
    var maxValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (comparator.compare(maxValue, v) < 0) {
            maxValue = v
        }
    }
    return maxValue
}

/**
 * Returns the largest character or `null` if there are no characters.
 */
@SinceKotlin("1.4")
public fun CharSequence.maxOrNull(): Char? {
    if (isEmpty()) return null
    var max = this[0]
    for (i in 1..lastIndex) {
        val e = this[i]
        if (max < e) max = e
    }
    return max
}

@Deprecated("Use maxWithOrNull instead.", ReplaceWith("maxWithOrNull(comparator)"))
@DeprecatedSinceKotlin(warningSince = "1.4")
public fun CharSequence.maxWith(comparator: Comparator<in Char>): Char? {
    return maxWithOrNull(comparator)
}

/**
 * Returns the first character having the largest value according to the provided [comparator] or `null` if there are no characters.
 */
@SinceKotlin("1.4")
public fun CharSequence.maxWithOrNull(comparator: Comparator<in Char>): Char? {
    if (isEmpty()) return null
    var max = this[0]
    for (i in 1..lastIndex) {
        val e = this[i]
        if (comparator.compare(max, e) < 0) max = e
    }
    return max
}

@Deprecated("Use minOrNull instead.", ReplaceWith("minOrNull()"))
@DeprecatedSinceKotlin(warningSince = "1.4")
public fun CharSequence.min(): Char? {
    return minOrNull()
}

@Deprecated("Use minByOrNull instead.", ReplaceWith("minByOrNull(selector)"))
@DeprecatedSinceKotlin(warningSince = "1.4")
public inline fun <R : Comparable<R>> CharSequence.minBy(selector: (Char) -> R): Char? {
    return minByOrNull(selector)
}

/**
 * Returns the first character yielding the smallest value of the given function or `null` if there are no characters.
 * 
 * @sample samples.collections.Collections.Aggregates.minByOrNull
 */
@SinceKotlin("1.4")
public inline fun <R : Comparable<R>> CharSequence.minByOrNull(selector: (Char) -> R): Char? {
    if (isEmpty()) return null
    var minElem = this[0]
    val lastIndex = this.lastIndex
    if (lastIndex == 0) return minElem
    var minValue = selector(minElem)
    for (i in 1..lastIndex) {
        val e = this[i]
        val v = selector(e)
        if (minValue > v) {
            minElem = e
            minValue = v
        }
    }
    return minElem
}

/**
 * Returns the smallest value among all values produced by [selector] function
 * applied to each character in the char sequence.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.minOf(selector: (Char) -> Double): Double {
    if (isEmpty()) throw NoSuchElementException()
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        minValue = minOf(minValue, v)
    }
    return minValue
}

/**
 * Returns the smallest value among all values produced by [selector] function
 * applied to each character in the char sequence.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.minOf(selector: (Char) -> Float): Float {
    if (isEmpty()) throw NoSuchElementException()
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        minValue = minOf(minValue, v)
    }
    return minValue
}

/**
 * Returns the smallest value among all values produced by [selector] function
 * applied to each character in the char sequence.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R : Comparable<R>> CharSequence.minOf(selector: (Char) -> R): R {
    if (isEmpty()) throw NoSuchElementException()
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (minValue > v) {
            minValue = v
        }
    }
    return minValue
}

/**
 * Returns the smallest value among all values produced by [selector] function
 * applied to each character in the char sequence or `null` if there are no characters.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.minOfOrNull(selector: (Char) -> Double): Double? {
    if (isEmpty()) return null
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        minValue = minOf(minValue, v)
    }
    return minValue
}

/**
 * Returns the smallest value among all values produced by [selector] function
 * applied to each character in the char sequence or `null` if there are no characters.
 * 
 * If any of values produced by [selector] function is `NaN`, the returned result is `NaN`.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun CharSequence.minOfOrNull(selector: (Char) -> Float): Float? {
    if (isEmpty()) return null
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        minValue = minOf(minValue, v)
    }
    return minValue
}

/**
 * Returns the smallest value among all values produced by [selector] function
 * applied to each character in the char sequence or `null` if there are no characters.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R : Comparable<R>> CharSequence.minOfOrNull(selector: (Char) -> R): R? {
    if (isEmpty()) return null
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (minValue > v) {
            minValue = v
        }
    }
    return minValue
}

/**
 * Returns the smallest value according to the provided [comparator]
 * among all values produced by [selector] function applied to each character in the char sequence.
 * 
 * @throws NoSuchElementException if the char sequence is empty.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R> CharSequence.minOfWith(comparator: Comparator<in R>, selector: (Char) -> R): R {
    if (isEmpty()) throw NoSuchElementException()
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (comparator.compare(minValue, v) > 0) {
            minValue = v
        }
    }
    return minValue
}

/**
 * Returns the smallest value according to the provided [comparator]
 * among all values produced by [selector] function applied to each character in the char sequence or `null` if there are no characters.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.internal.InlineOnly
public inline fun <R> CharSequence.minOfWithOrNull(comparator: Comparator<in R>, selector: (Char) -> R): R? {
    if (isEmpty()) return null
    var minValue = selector(this[0])
    for (i in 1..lastIndex) {
        val v = selector(this[i])
        if (comparator.compare(minValue, v) > 0) {
            minValue = v
        }
    }
    return minValue
}

/**
 * Returns the smallest character or `null` if there are no characters.
 */
@SinceKotlin("1.4")
public fun CharSequence.minOrNull(): Char? {
    if (isEmpty()) return null
    var min = this[0]
    for (i in 1..lastIndex) {
        val e = this[i]
        if (min > e) min = e
    }
    return min
}

@Deprecated("Use minWithOrNull instead.", ReplaceWith("minWithOrNull(comparator)"))
@DeprecatedSinceKotlin(warningSince = "1.4")
public fun CharSequence.minWith(comparator: Comparator<in Char>): Char? {
    return minWithOrNull(comparator)
}

/**
 * Returns the first character having the smallest value according to the provided [comparator] or `null` if there are no characters.
 */
@SinceKotlin("1.4")
public fun CharSequence.minWithOrNull(comparator: Comparator<in Char>): Char? {
    if (isEmpty()) return null
    var min = this[0]
    for (i in 1..lastIndex) {
        val e = this[i]
        if (comparator.compare(min, e) > 0) min = e
    }
    return min
}

/**
 * Returns `true` if the char sequence has no characters.
 * 
 * @sample samples.collections.Collections.Aggregates.none
 */
public fun CharSequence.none(): Boolean {
    return isEmpty()
}

/**
 * Returns `true` if no characters match the given [predicate].
 * 
 * @sample samples.collections.Collections.Aggregates.noneWithPredicate
 */
public inline fun CharSequence.none(predicate: (Char) -> Boolean): Boolean {
    for (element in this) if (predicate(element)) return false
    return true
}

/**
 * Performs the given [action] on each character and returns the char sequence itself afterwards.
 */
@SinceKotlin("1.1")
public inline fun <S : CharSequence> S.onEach(action: (Char) -> Unit): S {
    return apply { for (element in this) action(element) }
}

/**
 * Performs the given [action] on each character, providing sequential index with the character,
 * and returns the char sequence itself afterwards.
 * @param [action] function that takes the index of a character and the character itself
 * and performs the action on the character.
 */
@SinceKotlin("1.4")
public inline fun <S : CharSequence> S.onEachIndexed(action: (index: Int, Char) -> Unit): S {
    return apply { forEachIndexed(action) }
}

/**
 * Accumulates value starting with the first character and applying [operation] from left to right
 * to current accumulator value and each character.
 * 
 * Throws an exception if this char sequence is empty. If the char sequence can be empty in an expected way,
 * please use [reduceOrNull] instead. It returns `null` when its receiver is empty.
 * 
 * @param [operation] function that takes current accumulator value and a character,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduce
 */
public inline fun CharSequence.reduce(operation: (acc: Char, Char) -> Char): Char {
    if (isEmpty())
        throw UnsupportedOperationException("Empty char sequence can't be reduced.")
    var accumulator = this[0]
    for (index in 1..lastIndex) {
        accumulator = operation(accumulator, this[index])
    }
    return accumulator
}

/**
 * Accumulates value starting with the first character and applying [operation] from left to right
 * to current accumulator value and each character with its index in the original char sequence.
 * 
 * Throws an exception if this char sequence is empty. If the char sequence can be empty in an expected way,
 * please use [reduceIndexedOrNull] instead. It returns `null` when its receiver is empty.
 * 
 * @param [operation] function that takes the index of a character, current accumulator value and the character itself,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduce
 */
public inline fun CharSequence.reduceIndexed(operation: (index: Int, acc: Char, Char) -> Char): Char {
    if (isEmpty())
        throw UnsupportedOperationException("Empty char sequence can't be reduced.")
    var accumulator = this[0]
    for (index in 1..lastIndex) {
        accumulator = operation(index, accumulator, this[index])
    }
    return accumulator
}

/**
 * Accumulates value starting with the first character and applying [operation] from left to right
 * to current accumulator value and each character with its index in the original char sequence.
 * 
 * Returns `null` if the char sequence is empty.
 * 
 * @param [operation] function that takes the index of a character, current accumulator value and the character itself,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduceOrNull
 */
@SinceKotlin("1.4")
public inline fun CharSequence.reduceIndexedOrNull(operation: (index: Int, acc: Char, Char) -> Char): Char? {
    if (isEmpty())
        return null
    var accumulator = this[0]
    for (index in 1..lastIndex) {
        accumulator = operation(index, accumulator, this[index])
    }
    return accumulator
}

/**
 * Accumulates value starting with the first character and applying [operation] from left to right
 * to current accumulator value and each character.
 * 
 * Returns `null` if the char sequence is empty.
 * 
 * @param [operation] function that takes current accumulator value and a character,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduceOrNull
 */
@SinceKotlin("1.4")
@WasExperimental(ExperimentalStdlibApi::class)
public inline fun CharSequence.reduceOrNull(operation: (acc: Char, Char) -> Char): Char? {
    if (isEmpty())
        return null
    var accumulator = this[0]
    for (index in 1..lastIndex) {
        accumulator = operation(accumulator, this[index])
    }
    return accumulator
}

/**
 * Accumulates value starting with the last character and applying [operation] from right to left
 * to each character and current accumulator value.
 * 
 * Throws an exception if this char sequence is empty. If the char sequence can be empty in an expected way,
 * please use [reduceRightOrNull] instead. It returns `null` when its receiver is empty.
 * 
 * @param [operation] function that takes a character and current accumulator value,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduceRight
 */
public inline fun CharSequence.reduceRight(operation: (Char, acc: Char) -> Char): Char {
    var index = lastIndex
    if (index < 0) throw UnsupportedOperationException("Empty char sequence can't be reduced.")
    var accumulator = get(index--)
    while (index >= 0) {
        accumulator = operation(get(index--), accumulator)
    }
    return accumulator
}

/**
 * Accumulates value starting with the last character and applying [operation] from right to left
 * to each character with its index in the original char sequence and current accumulator value.
 * 
 * Throws an exception if this char sequence is empty. If the char sequence can be empty in an expected way,
 * please use [reduceRightIndexedOrNull] instead. It returns `null` when its receiver is empty.
 * 
 * @param [operation] function that takes the index of a character, the character itself and current accumulator value,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduceRight
 */
public inline fun CharSequence.reduceRightIndexed(operation: (index: Int, Char, acc: Char) -> Char): Char {
    var index = lastIndex
    if (index < 0) throw UnsupportedOperationException("Empty char sequence can't be reduced.")
    var accumulator = get(index--)
    while (index >= 0) {
        accumulator = operation(index, get(index), accumulator)
        --index
    }
    return accumulator
}

/**
 * Accumulates value starting with the last character and applying [operation] from right to left
 * to each character with its index in the original char sequence and current accumulator value.
 * 
 * Returns `null` if the char sequence is empty.
 * 
 * @param [operation] function that takes the index of a character, the character itself and current accumulator value,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduceRightOrNull
 */
@SinceKotlin("1.4")
public inline fun CharSequence.reduceRightIndexedOrNull(operation: (index: Int, Char, acc: Char) -> Char): Char? {
    var index = lastIndex
    if (index < 0) return null
    var accumulator = get(index--)
    while (index >= 0) {
        accumulator = operation(index, get(index), accumulator)
        --index
    }
    return accumulator
}

/**
 * Accumulates value starting with the last character and applying [operation] from right to left
 * to each character and current accumulator value.
 * 
 * Returns `null` if the char sequence is empty.
 * 
 * @param [operation] function that takes a character and current accumulator value,
 * and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.reduceRightOrNull
 */
@SinceKotlin("1.4")
@WasExperimental(ExperimentalStdlibApi::class)
public inline fun CharSequence.reduceRightOrNull(operation: (Char, acc: Char) -> Char): Char? {
    var index = lastIndex
    if (index < 0) return null
    var accumulator = get(index--)
    while (index >= 0) {
        accumulator = operation(get(index--), accumulator)
    }
    return accumulator
}

/**
 * Returns a list containing successive accumulation values generated by applying [operation] from left to right
 * to each character and current accumulator value that starts with [initial] value.
 * 
 * Note that `acc` value passed to [operation] function should not be mutated;
 * otherwise it would affect the previous value in resulting list.
 * 
 * @param [operation] function that takes current accumulator value and a character, and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.runningFold
 */
@SinceKotlin("1.4")
public inline fun <R> CharSequence.runningFold(initial: R, operation: (acc: R, Char) -> R): List<R> {
    if (isEmpty()) return listOf(initial)
    val result = ArrayList<R>(length + 1).apply { add(initial) }
    var accumulator = initial
    for (element in this) {
        accumulator = operation(accumulator, element)
        result.add(accumulator)
    }
    return result
}

/**
 * Returns a list containing successive accumulation values generated by applying [operation] from left to right
 * to each character, its index in the original char sequence and current accumulator value that starts with [initial] value.
 * 
 * Note that `acc` value passed to [operation] function should not be mutated;
 * otherwise it would affect the previous value in resulting list.
 * 
 * @param [operation] function that takes the index of a character, current accumulator value
 * and the character itself, and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.runningFold
 */
@SinceKotlin("1.4")
public inline fun <R> CharSequence.runningFoldIndexed(initial: R, operation: (index: Int, acc: R, Char) -> R): List<R> {
    if (isEmpty()) return listOf(initial)
    val result = ArrayList<R>(length + 1).apply { add(initial) }
    var accumulator = initial
    for (index in indices) {
        accumulator = operation(index, accumulator, this[index])
        result.add(accumulator)
    }
    return result
}

/**
 * Returns a list containing successive accumulation values generated by applying [operation] from left to right
 * to each character and current accumulator value that starts with the first character of this char sequence.
 * 
 * Note that `acc` value passed to [operation] function should not be mutated;
 * otherwise it would affect the previous value in resulting list.
 * 
 * @param [operation] function that takes current accumulator value and a character, and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.runningReduce
 */
@SinceKotlin("1.4")
public inline fun CharSequence.runningReduce(operation: (acc: Char, Char) -> Char): List<Char> {
    if (isEmpty()) return emptyList()
    var accumulator = this[0]
    val result = ArrayList<Char>(length).apply { add(accumulator) }
    for (index in 1 until length) {
        accumulator = operation(accumulator, this[index])
        result.add(accumulator)
    }
    return result
}

/**
 * Returns a list containing successive accumulation values generated by applying [operation] from left to right
 * to each character, its index in the original char sequence and current accumulator value that starts with the first character of this char sequence.
 * 
 * Note that `acc` value passed to [operation] function should not be mutated;
 * otherwise it would affect the previous value in resulting list.
 * 
 * @param [operation] function that takes the index of a character, current accumulator value
 * and the character itself, and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.runningReduce
 */
@SinceKotlin("1.4")
public inline fun CharSequence.runningReduceIndexed(operation: (index: Int, acc: Char, Char) -> Char): List<Char> {
    if (isEmpty()) return emptyList()
    var accumulator = this[0]
    val result = ArrayList<Char>(length).apply { add(accumulator) }
    for (index in 1 until length) {
        accumulator = operation(index, accumulator, this[index])
        result.add(accumulator)
    }
    return result
}

/**
 * Returns a list containing successive accumulation values generated by applying [operation] from left to right
 * to each character and current accumulator value that starts with [initial] value.
 * 
 * Note that `acc` value passed to [operation] function should not be mutated;
 * otherwise it would affect the previous value in resulting list.
 * 
 * @param [operation] function that takes current accumulator value and a character, and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.scan
 */
@SinceKotlin("1.4")
@WasExperimental(ExperimentalStdlibApi::class)
public inline fun <R> CharSequence.scan(initial: R, operation: (acc: R, Char) -> R): List<R> {
    return runningFold(initial, operation)
}

/**
 * Returns a list containing successive accumulation values generated by applying [operation] from left to right
 * to each character, its index in the original char sequence and current accumulator value that starts with [initial] value.
 * 
 * Note that `acc` value passed to [operation] function should not be mutated;
 * otherwise it would affect the previous value in resulting list.
 * 
 * @param [operation] function that takes the index of a character, current accumulator value
 * and the character itself, and calculates the next accumulator value.
 * 
 * @sample samples.collections.Collections.Aggregates.scan
 */
@SinceKotlin("1.4")
@WasExperimental(ExperimentalStdlibApi::class)
public inline fun <R> CharSequence.scanIndexed(initial: R, operation: (index: Int, acc: R, Char) -> R): List<R> {
    return runningFoldIndexed(initial, operation)
}

@Deprecated("Use runningReduce instead.", ReplaceWith("runningReduce(operation)"), level = DeprecationLevel.ERROR)
@SinceKotlin("1.3")
@ExperimentalStdlibApi
public inline fun CharSequence.scanReduce(operation: (acc: Char, Char) -> Char): List<Char> {
    return runningReduce(operation)
}

@Deprecated("Use runningReduceIndexed instead.", ReplaceWith("runningReduceIndexed(operation)"), level = DeprecationLevel.ERROR)
@SinceKotlin("1.3")
@ExperimentalStdlibApi
public inline fun CharSequence.scanReduceIndexed(operation: (index: Int, acc: Char, Char) -> Char): List<Char> {
    return runningReduceIndexed(operation)
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
public inline fun CharSequence.sumBy(selector: (Char) -> Int): Int {
    var sum: Int = 0
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
public inline fun CharSequence.sumByDouble(selector: (Char) -> Double): Double {
    var sum: Double = 0.0
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfDouble")
@kotlin.internal.InlineOnly
public inline fun CharSequence.sumOf(selector: (Char) -> Double): Double {
    var sum: Double = 0.toDouble()
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfInt")
@kotlin.internal.InlineOnly
public inline fun CharSequence.sumOf(selector: (Char) -> Int): Int {
    var sum: Int = 0.toInt()
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfLong")
@kotlin.internal.InlineOnly
public inline fun CharSequence.sumOf(selector: (Char) -> Long): Long {
    var sum: Long = 0.toLong()
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfUInt")
@ExperimentalUnsignedTypes
@kotlin.internal.InlineOnly
public inline fun CharSequence.sumOf(selector: (Char) -> UInt): UInt {
    var sum: UInt = 0.toUInt()
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Returns the sum of all values produced by [selector] function applied to each character in the char sequence.
 */
@SinceKotlin("1.4")
@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfULong")
@ExperimentalUnsignedTypes
@kotlin.internal.InlineOnly
public inline fun CharSequence.sumOf(selector: (Char) -> ULong): ULong {
    var sum: ULong = 0.toULong()
    for (element in this) {
        sum += selector(element)
    }
    return sum
}

/**
 * Splits this char sequence into a list of strings each not exceeding the given [size].
 * 
 * The last string in the resulting list may have less characters than the given [size].
 * 
 * @param size the number of elements to take in each string, must be positive and can be greater than the number of elements in this char sequence.
 * 
 * @sample samples.text.Strings.chunked
 */
@SinceKotlin("1.2")
public fun CharSequence.chunked(size: Int): List<String> {
    return windowed(size, size, partialWindows = true)
}

/**
 * Splits this char sequence into several char sequences each not exceeding the given [size]
 * and applies the given [transform] function to an each.
 * 
 * @return list of results of the [transform] applied to an each char sequence.
 * 
 * Note that the char sequence passed to the [transform] function is ephemeral and is valid only inside that function.
 * You should not store it or allow it to escape in some way, unless you made a snapshot of it.
 * The last char sequence may have less characters than the given [size].
 * 
 * @param size the number of elements to take in each char sequence, must be positive and can be greater than the number of elements in this char sequence.
 * 
 * @sample samples.text.Strings.chunkedTransform
 */
@SinceKotlin("1.2")
public fun <R> CharSequence.chunked(size: Int, transform: (CharSequence) -> R): List<R> {
    return windowed(size, size, partialWindows = true, transform = transform)
}

/**
 * Splits this char sequence into a sequence of strings each not exceeding the given [size].
 * 
 * The last string in the resulting sequence may have less characters than the given [size].
 * 
 * @param size the number of elements to take in each string, must be positive and can be greater than the number of elements in this char sequence.
 * 
 * @sample samples.collections.Collections.Transformations.chunked
 */
@SinceKotlin("1.2")
public fun CharSequence.chunkedSequence(size: Int): Sequence<String> {
    return chunkedSequence(size) { it.toString() }
}

/**
 * Splits this char sequence into several char sequences each not exceeding the given [size]
 * and applies the given [transform] function to an each.
 * 
 * @return sequence of results of the [transform] applied to an each char sequence.
 * 
 * Note that the char sequence passed to the [transform] function is ephemeral and is valid only inside that function.
 * You should not store it or allow it to escape in some way, unless you made a snapshot of it.
 * The last char sequence may have less characters than the given [size].
 * 
 * @param size the number of elements to take in each char sequence, must be positive and can be greater than the number of elements in this char sequence.
 * 
 * @sample samples.text.Strings.chunkedTransformToSequence
 */
@SinceKotlin("1.2")
public fun <R> CharSequence.chunkedSequence(size: Int, transform: (CharSequence) -> R): Sequence<R> {
    return windowedSequence(size, size, partialWindows = true, transform = transform)
}

/**
 * Splits the original char sequence into pair of char sequences,
 * where *first* char sequence contains characters for which [predicate] yielded `true`,
 * while *second* char sequence contains characters for which [predicate] yielded `false`.
 * 
 * @sample samples.text.Strings.partition
 */
public inline fun CharSequence.partition(predicate: (Char) -> Boolean): Pair<CharSequence, CharSequence> {
    val first = StringBuilder()
    val second = StringBuilder()
    for (element in this) {
        if (predicate(element)) {
            first.append(element)
        } else {
            second.append(element)
        }
    }
    return Pair(first, second)
}

/**
 * Splits the original string into pair of strings,
 * where *first* string contains characters for which [predicate] yielded `true`,
 * while *second* string contains characters for which [predicate] yielded `false`.
 * 
 * @sample samples.text.Strings.partition
 */
public inline fun String.partition(predicate: (Char) -> Boolean): Pair<String, String> {
    val first = StringBuilder()
    val second = StringBuilder()
    for (element in this) {
        if (predicate(element)) {
            first.append(element)
        } else {
            second.append(element)
        }
    }
    return Pair(first.toString(), second.toString())
}

/**
 * Returns a list of snapshots of the window of the given [size]
 * sliding along this char sequence with the given [step], where each
 * snapshot is a string.
 * 
 * Several last strings may have less characters than the given [size].
 * 
 * Both [size] and [step] must be positive and can be greater than the number of elements in this char sequence.
 * @param size the number of elements to take in each window
 * @param step the number of elements to move the window forward by on an each step, by default 1
 * @param partialWindows controls whether or not to keep partial windows in the end if any,
 * by default `false` which means partial windows won't be preserved
 * 
 * @sample samples.collections.Sequences.Transformations.takeWindows
 */
@SinceKotlin("1.2")
public fun CharSequence.windowed(size: Int, step: Int = 1, partialWindows: Boolean = false): List<String> {
    return windowed(size, step, partialWindows) { it.toString() }
}

/**
 * Returns a list of results of applying the given [transform] function to
 * an each char sequence representing a view over the window of the given [size]
 * sliding along this char sequence with the given [step].
 * 
 * Note that the char sequence passed to the [transform] function is ephemeral and is valid only inside that function.
 * You should not store it or allow it to escape in some way, unless you made a snapshot of it.
 * Several last char sequences may have less characters than the given [size].
 * 
 * Both [size] and [step] must be positive and can be greater than the number of elements in this char sequence.
 * @param size the number of elements to take in each window
 * @param step the number of elements to move the window forward by on an each step, by default 1
 * @param partialWindows controls whether or not to keep partial windows in the end if any,
 * by default `false` which means partial windows won't be preserved
 * 
 * @sample samples.collections.Sequences.Transformations.averageWindows
 */
@SinceKotlin("1.2")
public fun <R> CharSequence.windowed(size: Int, step: Int = 1, partialWindows: Boolean = false, transform: (CharSequence) -> R): List<R> {
    checkWindowSizeStep(size, step)
    val thisSize = this.length
    val resultCapacity = thisSize / step + if (thisSize % step == 0) 0 else 1
    val result = ArrayList<R>(resultCapacity)
    var index = 0
    while (index in 0 until thisSize) {
        val end = index + size
        val coercedEnd = if (end < 0 || end > thisSize) { if (partialWindows) thisSize else break } else end
        result.add(transform(subSequence(index, coercedEnd)))
        index += step
    }
    return result
}

/**
 * Returns a sequence of snapshots of the window of the given [size]
 * sliding along this char sequence with the given [step], where each
 * snapshot is a string.
 * 
 * Several last strings may have less characters than the given [size].
 * 
 * Both [size] and [step] must be positive and can be greater than the number of elements in this char sequence.
 * @param size the number of elements to take in each window
 * @param step the number of elements to move the window forward by on an each step, by default 1
 * @param partialWindows controls whether or not to keep partial windows in the end if any,
 * by default `false` which means partial windows won't be preserved
 * 
 * @sample samples.collections.Sequences.Transformations.takeWindows
 */
@SinceKotlin("1.2")
public fun CharSequence.windowedSequence(size: Int, step: Int = 1, partialWindows: Boolean = false): Sequence<String> {
    return windowedSequence(size, step, partialWindows) { it.toString() }
}

/**
 * Returns a sequence of results of applying the given [transform] function to
 * an each char sequence representing a view over the window of the given [size]
 * sliding along this char sequence with the given [step].
 * 
 * Note that the char sequence passed to the [transform] function is ephemeral and is valid only inside that function.
 * You should not store it or allow it to escape in some way, unless you made a snapshot of it.
 * Several last char sequences may have less characters than the given [size].
 * 
 * Both [size] and [step] must be positive and can be greater than the number of elements in this char sequence.
 * @param size the number of elements to take in each window
 * @param step the number of elements to move the window forward by on an each step, by default 1
 * @param partialWindows controls whether or not to keep partial windows in the end if any,
 * by default `false` which means partial windows won't be preserved
 * 
 * @sample samples.collections.Sequences.Transformations.averageWindows
 */
@SinceKotlin("1.2")
public fun <R> CharSequence.windowedSequence(size: Int, step: Int = 1, partialWindows: Boolean = false, transform: (CharSequence) -> R): Sequence<R> {
    checkWindowSizeStep(size, step)
    val windows = (if (partialWindows) indices else 0 until length - size + 1) step step
    return windows.asSequence().map { index ->
        val end = index + size
        val coercedEnd = if (end < 0 || end > length) length else end
        transform(subSequence(index, coercedEnd))
    }
}

/**
 * Returns a list of pairs built from the characters of `this` and the [other] char sequences with the same index
 * The returned list has length of the shortest char sequence.
 * 
 * @sample samples.text.Strings.zip
 */
public infix fun CharSequence.zip(other: CharSequence): List<Pair<Char, Char>> {
    return zip(other) { c1, c2 -> c1 to c2 }
}

/**
 * Returns a list of values built from the characters of `this` and the [other] char sequences with the same index
 * using the provided [transform] function applied to each pair of characters.
 * The returned list has length of the shortest char sequence.
 * 
 * @sample samples.text.Strings.zipWithTransform
 */
public inline fun <V> CharSequence.zip(other: CharSequence, transform: (a: Char, b: Char) -> V): List<V> {
    val length = minOf(this.length, other.length)
    val list = ArrayList<V>(length)
    for (i in 0 until length) {
        list.add(transform(this[i], other[i]))
    }
    return list
}

/**
 * Returns a list of pairs of each two adjacent characters in this char sequence.
 * 
 * The returned list is empty if this char sequence contains less than two characters.
 * 
 * @sample samples.collections.Collections.Transformations.zipWithNext
 */
@SinceKotlin("1.2")
public fun CharSequence.zipWithNext(): List<Pair<Char, Char>> {
    return zipWithNext { a, b -> a to b }
}

/**
 * Returns a list containing the results of applying the given [transform] function
 * to an each pair of two adjacent characters in this char sequence.
 * 
 * The returned list is empty if this char sequence contains less than two characters.
 * 
 * @sample samples.collections.Collections.Transformations.zipWithNextToFindDeltas
 */
@SinceKotlin("1.2")
public inline fun <R> CharSequence.zipWithNext(transform: (a: Char, b: Char) -> R): List<R> {
    val size = length - 1
    if (size < 1) return emptyList()
    val result = ArrayList<R>(size)
    for (index in 0 until size) {
        result.add(transform(this[index], this[index + 1]))
    }
    return result
}

/**
 * Creates an [Iterable] instance that wraps the original char sequence returning its characters when being iterated.
 */
public fun CharSequence.asIterable(): Iterable<Char> {
    if (this is String && isEmpty()) return emptyList()
    return Iterable { this.iterator() }
}

/**
 * Creates a [Sequence] instance that wraps the original char sequence returning its characters when being iterated.
 */
public fun CharSequence.asSequence(): Sequence<Char> {
    if (this is String && isEmpty()) return emptySequence()
    return Sequence { this.iterator() }
}

